import Vue from 'vue';
import { findIndex } from 'lodash-es';
import axios from '../../../plugins/axios.js';

export default {
    async fetchImports() {
        const response = await axios.get('v1/imports');

        this.imports = response.data.data;

        return response.data.data;
    },
    async fetchImport(importId) {
        const response = await axios.get(`v1/imports/${importId}`);

        this.currentImport = response.data.data;

        return response.data.data;
    },
    async fetchCampaigns(accountId) {
        const response = await axios.get(`v1/accounts/${accountId}/campaigns`);

        this.accountCampaigns = response.data.data;

        return response.data.data;
    },
    async updateImport(id, payload) {
        const response = await axios.put(`v1/imports/${id}`, payload);

        this.updateImportState(response.data.data);

        return response.data.data;
    },
    async deleteImport(importId) {
        const response = await axios.delete(`v1/imports/${importId}`);

        this.removeImport(response.data.data);

        return response.data.data;
    },
    async createImport(payload) {
        const response = await axios.post('v1/imports', payload);

        this.imports.push(response.data.data);

        return response.data.data;
    },
    async processImport({ importId, payload }) {
        const response = await axios.put(`v1/imports/${importId}/process`, payload);

        return response.data.data;
    },
    updateImportState(payload) {
        const index = findIndex(this.imports, currentImport => {
            return currentImport.id == payload.id;
        });

        if (index < 0) return;

        Vue.set(this.imports, index, payload);
    },
    removeImport(payload) {
        const index = findIndex(this.imports, currentImport => {
            return currentImport.id == payload.id;
        });

        if (index < 0) return;

        this.imports.splice(index, 1);
    },
};
