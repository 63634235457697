<template>
    <activix-modal :opened="opened" @close="close" @closed="onClosed">
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('imports.unselectedFieldsModal.title') }}
            </h4>
        </template>

        <template slot="body">
            <div class="alert alert-warning text-center">
                <p>{{ $t('imports.unselectedFieldsModal.description') }}</p>
            </div>

            <table class="table">
                <tr>
                    <th>{{ $t('imports.unselectedFieldsModal.fieldName') }}</th>
                </tr>
                <tr :key="field.index" v-for="field in unselectedCapitalizedFields">
                    <td>
                        {{ field }}
                    </td>
                </tr>
            </table>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.cancel') }}
            </activix-button>
            <activix-button type="primary" @click="approve">
                {{ $t('general.continue') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { capitalize } from 'lodash-es';

    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
                default: false,
            },
            unselectedFields: {
                type: Array,
                required: true,
            },
        },

        computed: {
            unselectedCapitalizedFields() {
                return this.unselectedFields.map(item => {
                    return capitalize(item);
                });
            },
        },

        methods: {
            approve() {
                this.$emit('approve');
                this.close();
            },

            close() {
                this.$emit('update:opened', false);
            },

            onClosed() {
                this.$emit('closed');
            },
        },
    };
</script>
