<template>
    <div class="relative" :class="{ open: openSuggestion }">
        <input
            class="form-control"
            type="text"
            :class="inputClass"
            @click="forceOpen = false"
            @dblclick="showSuggestions"
            @input="change"
            @keydown.down="down"
            @keydown.enter="enter"
            @keydown.up="up"
            v-on-clickaway="blur"
            v-model="currentSelectionText"
        />
        <ul class="dropdown-menu w-full">
            <li
                class="py-1 px-6 cursor-pointer"
                :class="[{ 'bg-blue-500': isActive(index), 'text-white': isActive(index) }]"
                :key="index"
                @click="suggestionClick(index)"
                v-for="(suggestion, index) in matches"
            >
                {{ suggestion.label }}
            </li>
        </ul>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { isPlainObject } from 'lodash-es';

    export default {
        props: {
            suggestions: {
                type: [Array, Promise],
                required: true,
            },

            selection: {
                required: true,
            },

            inputClass: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                open: false,
                current: null,
                forceOpen: false,
                currentSelection: null,
                currentSelectionText: null,
                normalizedSuggestions: [],
            };
        },

        computed: {
            matches() {
                return this.normalizedSuggestions.filter(suggestion => {
                    return (
                        suggestion.label.toLowerCase().indexOf(this.currentSelectionText.toLowerCase()) >= 0 ||
                        this.forceOpen
                    );
                });
            },

            openSuggestion() {
                return (
                    this.forceOpen == true ||
                    (this.currentSelectionText && this.matches.length > 0 && this.open === true)
                );
            },
        },

        watch: {
            suggestions: {
                immediate: true,
                handler() {
                    if (this.suggestions instanceof Promise) {
                        this.suggestions.then(suggestions => {
                            this.updateSuggestions(suggestions);
                        });
                    } else {
                        this.updateSuggestions(this.suggestions);
                    }
                },
            },

            selection: {
                immediate: true,
                handler() {
                    this.currentSelection = this.normalizedSuggestions.find(
                        suggestion => suggestion.id == this.selection,
                    ) || {
                        id: this.selection,
                        label: this.selection,
                    };
                },
            },
            'currentSelection.id': {
                immediate: true,
                handler() {
                    this.currentSelectionText = this.currentSelection ? this.currentSelection.label : '';
                },
            },
        },

        methods: {
            enter() {
                this.updateCurrentSelectionToIndex(this.current);
                this.updateSelection();
            },

            blur() {
                this.updateSelection();
            },

            up() {
                if (this.current > 0) {
                    this.current--;
                }
            },

            down() {
                if (this.current < this.matches.length - 1) {
                    this.current++;
                }
            },

            isActive(index) {
                return index === this.current;
            },

            change() {
                if (this.open == false) {
                    this.open = true;
                    this.current = 0;
                }

                this.currentSelection = {
                    id: this.currentSelectionText,
                    label: this.currentSelectionText,
                };

                this.forceOpen = false;
            },

            showSuggestions() {
                this.forceOpen = !this.forceOpen;
            },

            suggestionClick(index) {
                this.updateCurrentSelectionToIndex(index);
                this.forceOpen = false;
                this.updateSelection();
            },

            updateCurrentSelectionToIndex(index) {
                if (index !== null && this.matches.length) {
                    this.currentSelection = this.matches[index];
                }
            },

            updateSelection() {
                this.open = false;
                this.current = null;

                if (this.currentSelection.id != this.selection) {
                    this.$emit('update:selection', this.currentSelection.id);
                }
            },

            updateSuggestions(suggestions) {
                this.normalizedSuggestions = suggestions.map(suggestion => {
                    if (isPlainObject(suggestion)) {
                        return suggestion;
                    }

                    return {
                        id: suggestion,
                        label: suggestion,
                    };
                });
            },
        },
    };
</script>
