<template>
    <div>
        <div class="flex items-center justify-center absolute h-full w-full top-0" v-if="!autoMatchDone">
            <activix-spinner :size="50" :message="$t('general.loadingMatching')" />
        </div>
        <div v-if="autoMatchDone">
            <div class="alert alert-warning" v-if="hasLeadTypeWalkIn">
                <div class="font-bold">
                    {{ $t('error.warning') }}
                </div>
                {{ $t('imports.alerts.presentedDateAndService') }}
            </div>

            <div class="alert alert-warning" v-if="hasLeadTypeDMS">
                <div class="font-bold">
                    {{ $t('error.warning') }}
                </div>
                <div v-html="$t('imports.alerts.service')" />
            </div>

            <div class="alert alert-warning" v-if="hasLeadTypePreBooking">
                <div class="font-bold">
                    {{ $t('error.warning') }}
                </div>
                <div v-html="$t('imports.alerts.preBooking')" />
            </div>

            <h4>{{ $t('imports.subTitle') }}</h4>

            <div class="flex items-end space-x-4">
                <div class="w-1/4">
                    <label>
                        {{ $t('imports.leadType') }}
                        <span class="form-star">*</span>
                    </label>
                    <activix-multiselect
                        :allow-empty="false"
                        :enable-highlight="hasLeadType"
                        :options="filteredLeadTypes"
                        :white="true"
                        @update="updateLeadType"
                    />
                </div>
                <div class="w-1/4">
                    <activix-tooltip :content="$t('imports.tooltips.importRule')">
                        <label>
                            {{ $t('imports.importRule') }}
                            <span class="form-star">*</span>
                        </label>
                    </activix-tooltip>
                    <activix-multiselect
                        :allow-empty="false"
                        :custom-label="formatImportRule"
                        :enable-highlight="hasImportRule"
                        :options="importRules"
                        :searchable="false"
                        :selected="importRule"
                        :white="true"
                        @update="updateImportRule"
                    />
                </div>
                <template v-if="hasLeadTypeEvent">
                    <div class="w-1/4">
                        <label>
                            {{ $t('imports.eventStartDate') }}
                            <span class="form-star">*</span>
                        </label>
                        <date-time-picker
                            :date-only="true"
                            :end-date="eventEndDate"
                            :start-date="getCurrentTime()"
                            :value="eventStartDate"
                            :white="true"
                            v-model="eventStartDate"
                        />
                    </div>
                    <div class="w-1/4">
                        <activix-tooltip :content="$t('imports.tooltips.eventEndDate')">
                            <label>
                                {{ $t('imports.eventEndDate') }}
                                <span class="form-star">*</span>
                            </label>
                        </activix-tooltip>
                        <date-time-picker
                            :date-only="true"
                            :start-date="eventStartDate || getCurrentTime()"
                            :value="eventEndDate"
                            :white="true"
                            v-model="eventEndDate"
                        />
                    </div>
                    <div class="w-1/4">
                        <label>
                            {{ $t('imports.fields.event') }}
                            <span class="form-star">*</span>
                        </label>
                        <auto-complete
                            input-class="input-white"
                            :suggestions="eventNames"
                            :selection="eventName"
                            @update:selection="val => (eventName = val)"
                        />
                    </div>
                    <div class="w-1/4">
                        <label>
                            {{ $t('imports.fields.segment') }}
                            <span class="form-star">*</span>
                        </label>
                        <activix-multiselect
                            :allow-empty="false"
                            :custom-label="formatEventSegment"
                            :enable-highlight="hasEventSegment"
                            :options="segments"
                            :selected="eventSegment"
                            :white="true"
                            @update="updateEventSegment"
                        />
                    </div>
                </template>
            </div>

            <div class="flex-1 my-4">
                <activix-checkbox :value="allowEmptyCreatedAt" @click.native.prevent="onAllowEmptyCreatedDate">
                    {{ $t('imports.allowEmptyCreatedAt.button') }}
                </activix-checkbox>
            </div>

            <div
                class="box | mb-6"
                :class="{ loading: $wait.is('fetching.import') }"
                :key="section"
                v-for="(columns, section) in sections"
            >
                <div class="box-header | border-b">
                    <h4 class="box-title">
                        {{ getSectionName(section) }}
                        <span class="small" v-if="section == 'revivalService'">
                            {{ $t('imports.sectionsInfo.revivalService') }}
                        </span>
                    </h4>
                </div>
                <div class="box-body">
                    <div class="row">
                        <template v-for="(importable, column) in columns">
                            <div class="col-xs-12 col-lg-3" :key="column" v-if="importable && showField(column)">
                                <div class="form-group">
                                    <activix-tooltip
                                        :content="
                                            $te(`imports.tooltips.${column}`) ? $t(`imports.tooltips.${column}`) : ''
                                        "
                                    >
                                        <label>
                                            {{ getFieldName(column) }}
                                            <span class="form-star" v-if="isMandatory(column)">*</span>
                                        </label>
                                    </activix-tooltip>
                                    <activix-multiselect
                                        :options="editing == column ? heading : [fields[column]]"
                                        :selected="fields[column]"
                                        :enable-highlight="isSelected(column)"
                                        :clearable="true"
                                        :disabled="disabledField(column)"
                                        :placeholder="placeholderField(column)"
                                        @focus="editing = column"
                                        @update="updateField(column, arguments)"
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="row" v-if="hasFilteredFields">
                <div class="col-xs-12">
                    <div class="box" :class="{ loading: $wait.is('fetching.import') }">
                        <div class="box-header">
                            <h4 class="box-title">
                                {{ $t('imports.revision') }}
                            </h4>
                        </div>
                        <div class="box-body">
                            <table class="table">
                                <tr>
                                    <th>
                                        {{ $t('imports.fieldInFile') }}
                                    </th>
                                    <th>
                                        {{ $t('imports.fieldInDatabase') }}
                                    </th>
                                </tr>
                                <tr :key="column" v-for="(field, column) in filteredFields">
                                    <td>
                                        {{ field }}
                                    </td>
                                    <td>
                                        {{ getFieldName(column) }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="alert alert-danger" v-if="errors">
                <strong>
                    <icon name="regular/information-circle" class="mr-1" />
                    {{ $t('error.warning') }}
                </strong>
                <div :key="index" v-for="(error, index) in errors">
                    {{ error }}
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-xs-12">
                    <activix-button
                        type="primary"
                        class="float-right"
                        :disabled="disableSave"
                        :loading="$wait.is('saving.import')"
                        @click="triggerSave"
                    >
                        {{ $t('general.save') }}
                    </activix-button>
                </div>
            </div>

            <unselected-fields
                :unselected-fields="unselectedFields"
                :opened.sync="unselectedFieldsConfirmModalOpened"
                @closed="unselectedFieldsConfirmModalOpened = false"
                @approve="onBypassUnselected"
            />

            <activix-confirm-modal
                type="warning"
                :content="bypassEventDatesModalContent"
                portal="modal-1"
                :opened.sync="bypassEventDatesModal.opened"
                @approve="onBypassEventDates"
                @closed="bypassEventDatesModal.opened = false"
            />

            <activix-confirm-modal
                type="warning"
                portal="modal-2"
                :content="$t('imports.needSidModal.description')"
                :opened.sync="bypassSidModal.opened"
                @approve="onBypassSid"
                @closed="bypassSidModal.opened = false"
            />

            <activix-confirm-modal
                type="warning"
                :content="$t('imports.allowEmptyCreatedAt.description')"
                :opened.sync="allowEmptyCreatedDateModalOpened"
                @approve="allowEmptyCreatedAt = true"
                @deny="allowEmptyCreatedAt = false"
            />
        </div>
    </div>
</template>

<script>
    import { camelCase, cloneDeep, each, filter, includes, isFinite, merge, omit } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';
    import UnselectedFields from '../../components/modals/UnselectedFields.vue';
    import DateTimePicker from '../../components/inputs/DateTimePicker.vue';
    import AutoComplete from '../../components/inputs/AutoComplete.vue';
    import LeadType from '../../entities/LeadType.js';
    import TrackView from '../../mixins/TrackView.js';

    // Pinia
    import { useImportStore } from '../../store/modules/imports/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'ImportsMatch',

        components: {
            DateTimePicker,
            UnselectedFields,
            AutoComplete,
        },

        mixins: [TrackView],

        data() {
            return {
                heading: [],
                editing: null,
                autoMatchDone: false,
                bypassUnselectedModal: false,
                unselectedFieldsConfirmModalOpened: false,
                errors: null,
                eventEndDate: null,
                eventName: '',
                eventSegment: null,
                eventStartDate: null,
                fields: {},
                importRule: null,
                leadType: null,
                allowEmptyCreatedDateModalOpened: false,
                customFields: [],
                segments: [
                    'conquest',
                    'promo',
                    'notSold',
                    'service',
                    'loyalty',
                    'other',
                    'reminder',
                    'endWarranty',
                    'endLcap',
                    'endLnette',
                    'nowShow',
                    'csi',
                ],
                bypassSidModal: {
                    opened: false,
                    bypass: false,
                },
                bypassEventDatesModal: {
                    opened: false,
                    bypass: false,
                },
                allowEmptyCreatedAt: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs']),
            ...mapState(useImportStore, ['currentImport', 'accountCampaigns']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            bypassEventDatesModalContent() {
                if (!this.existingEventDates) {
                    return '';
                }

                return this.$t('imports.eventDifferentDatesModal.description', [
                    locale_dt(this.existingEventDates.start).humanShort(),
                    locale_dt(this.existingEventDates.end).humanShort(),
                ]);
            },

            sections() {
                const namingConventions = merge(this.configs.namingConventions, this.getCustomFields());

                return omit(namingConventions, ['custom']);
            },

            importRules() {
                let rules = ['merge', 'create'];

                if (this.leadType == LeadType.EVENT) {
                    rules = ['create'];
                }

                return rules;
            },

            disableSave() {
                if (!this.leadType || !this.importRule || this.$wait.is('saving.import')) {
                    return true;
                }

                for (const field of this.mandatoryFields) {
                    if (Array.isArray(field)) {
                        if (!field.some(value => this.fields[value])) {
                            return true;
                        }
                    } else if (!this.fields[field]) {
                        return true;
                    }
                }

                if (this.leadType == LeadType.EVENT) {
                    if (
                        !locale_dt(this.eventStartDate).isValid() ||
                        !locale_dt(this.eventEndDate).isValid() ||
                        !this.eventName ||
                        !this.eventSegment
                    ) {
                        return true;
                    }
                }

                return false;
            },

            eventNames() {
                return this.accountCampaigns.map(c => c.name);
            },

            filteredFields() {
                const fields = {};

                Object.keys(this.fields)
                    .sort()
                    .forEach(x => {
                        if (this.fields[x]) {
                            fields[x] = this.fields[x];
                        }
                    });

                return fields;
            },

            filteredLeadTypes() {
                return this.configs.leadTypes
                    .filter(leadType => {
                        return this.authUser.isAdmin() || ![LeadType.SMS, LeadType.EVENT].includes(leadType.id);
                    })
                    .map(leadType => {
                        return {
                            id: leadType.id,
                            label: this.$t(`leadTypes.${camelCase(leadType.name)}`),
                        };
                    })
                    .sort((a, b) => a.label.localeCompare(b.label));
            },

            importId() {
                return this.$route.params.id;
            },

            mandatoryFields() {
                const fields = [];

                switch (this.leadType) {
                    case LeadType.PHONE:
                        fields.push('call_date');
                        break;

                    case LeadType.WALK_IN:
                        fields.push('presented_date');
                        break;

                    case LeadType.RENEWAL:
                        fields.push('exchange_vehicles:end_contract_date');
                        fields.push('exchange_vehicles:modality');
                        break;

                    case LeadType.DMS:
                        fields.push(['service_advisor:', 'bdc_user:', 'user:']);
                        break;
                    default:
                }

                if (this.leadType != LeadType.EVENT && !this.allowEmptyCreatedAt) {
                    fields.push('created_at');
                }

                return fields;
            },

            unselectedFields() {
                return filter(this.heading, field => {
                    return !['id'].includes(field) && !includes(this.filteredFields, field);
                });
            },

            existingEventDates() {
                const existingEvent = this.accountCampaigns.find(c => c.name == this.eventName);

                return !existingEvent
                    ? null
                    : {
                        start: existingEvent.start_date,
                        end: existingEvent.end_date,
                    };
            },

            existingEventDifferentDates() {
                return (
                    this.existingEventDates &&
                    (!locale_dt(this.existingEventDates.start).isSameDay(locale_dt(this.eventStartDate)) ||
                        !locale_dt(this.existingEventDates.end).isSameDay(locale_dt(this.eventEndDate)))
                );
            },

            hasEventSegment() {
                return !empty(this.eventSegment);
            },

            hasFilteredFields() {
                return !empty(this.filteredFields);
            },

            hasImportRule() {
                return !empty(this.importRule);
            },

            hasLeadType() {
                return !empty(this.leadType);
            },

            hasLeadTypePhone() {
                return this.leadType === LeadType.PHONE;
            },

            hasLeadTypeWalkIn() {
                return this.leadType === LeadType.WALK_IN;
            },

            hasLeadTypeRenewal() {
                return this.leadType === LeadType.RENEWAL;
            },

            hasLeadTypeEvent() {
                return this.leadType === LeadType.EVENT;
            },

            hasLeadTypeDMS() {
                return this.leadType === LeadType.DMS;
            },

            hasLeadTypePreBooking() {
                return this.leadType === LeadType.PRE_BOOKING;
            },
        },

        watch: {
            currentImport() {
                this.setContextAccountAction(this.currentImport.account_id);
            },

            importId() {
                this.fetch();
            },

            leadType(newValue, oldValue) {
                if (newValue != oldValue && oldValue == LeadType.EVENT) {
                    this.eventName = '';
                    this.eventSegment = '';
                    this.eventStartDate = null;
                    this.eventEndDate = null;
                }

                if (newValue == LeadType.EVENT) {
                    this.importRule = 'create';
                }
            },
        },

        methods: {
            ...mapActions(useImportStore, ['fetchImport', 'fetchCampaigns', 'processImport']),
            ...mapActions(useContextStore, ['setContextAccountAction']),
            ...mapActions(useGlobalStore, ['appendNewError']),

            disabledField(column) {
                if (column == 'division:name' && this.hasLeadTypePreBooking) {
                    return true;
                }

                return false;
            },

            placeholderField(column) {
                if (column === 'division:name' && this.hasLeadTypePreBooking) {
                    return this.$t('divisions.service');
                }

                return '';
            },

            fetch() {
                if (!isFinite(parseInt(this.importId, 10))) {
                    this.$router.replace({ name: 'imports.index' });
                    return;
                }

                this.$wait.start('fetching.import');

                this.fetchImport(this.importId)
                    .then(data => {
                        // Make sure it's not already matched
                        if (data.started_at) {
                            this.$router.replace({ name: 'imports.index' });
                            return;
                        }

                        this.fetchCampaigns(this.currentImport.account_id);

                        if (this.currentImport.file_heading !== undefined) {
                            this.heading = this.currentImport.file_heading;
                        }

                        this.autoMatch();
                        this.autoMatchDone = true;
                        this.$wait.end('fetching.import');
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 403) {
                            this.$router.replace({ name: 'imports.index' });
                        }
                    });
            },

            autoMatch() {
                const fields = {};
                const heading = cloneDeep(this.heading);
                let index = null;

                each(this.sections, section => {
                    each(section, (importable, matchedCol) => {
                        if (importable && heading.includes(matchedCol)) {
                            fields[matchedCol] = matchedCol;
                            index = heading.indexOf(matchedCol);
                            heading.splice(index, 1);
                        }
                    });
                });

                this.fields = fields;
            },

            formatEventSegment(name) {
                return this.$t(`imports.segments.${name.label}`);
            },

            formatImportRule(value) {
                return this.$t(`imports.importRules.${value.label}`);
            },

            getCurrentTime() {
                return now().startOfDay().toString();
            },

            getFieldName(field) {
                if (field === 'postal_code') {
                    return this.$t(`imports.fields['${field}'].${this.contextAccount.country}`);
                }

                if (field.startsWith('customId_')) {
                    const length = 'customId_'.length;
                    const customField = this.contextAccount.getCustomFieldById(field.substr(length));
                    return customField
                        ? `${customField.name} ${customField.parentName ? ` (${customField.parentName})` : ''}`
                        : field;
                }

                return this.$t(`imports.fields['${field}']`);
            },

            getSectionName(section) {
                return this.$t(`imports.sections.${section}`);
            },

            isMandatory(field) {
                return this.mandatoryFields
                    .map(mandatoryField => {
                        return Array.isArray(mandatoryField) ? mandatoryField : [mandatoryField];
                    })
                    .some(mandatoryFieldArray => {
                        return mandatoryFieldArray.includes(field) &&
                            !mandatoryFieldArray.some(mandatoryField => this.fields[mandatoryField]);
                    });
            },

            isSelected(field) {
                return !!this.fields[field];
            },

            triggerSave() {
                if (!this.bypassUnselectedModal && !empty(this.unselectedFields)) {
                    this.showUnselectedModal();
                } else if (!this.bypassSidModal.bypass && this.leadType == LeadType.EVENT && empty(this.fields.sid)) {
                    this.bypassSidModal.opened = true;
                } else if (
                    !this.bypassEventDatesModal.bypass &&
                    this.leadType == LeadType.EVENT &&
                    this.existingEventDifferentDates
                ) {
                    this.bypassEventDatesModal.opened = true;
                } else {
                    this.save();
                }
            },

            onBypassEventDates() {
                this.bypassEventDatesModal.bypass = true;
                this.triggerSave();
            },

            onBypassUnselected() {
                this.bypassUnselectedModal = true;
                this.triggerSave();
            },

            onBypassSid() {
                this.bypassSidModal.bypass = true;
                this.triggerSave();
            },

            showUnselectedModal() {
                this.unselectedFieldsConfirmModalOpened = true;
            },

            save() {
                this.$wait.start('saving.import');
                this.errors = null;

                const payload = {
                    fields: this.fields,
                    leadType: this.leadType,
                    importRule: this.importRule,
                    eventStartDate: this.eventStartDate,
                    eventEndDate: this.eventEndDate,
                    eventName: this.eventName,
                    eventSegment: this.eventSegment,
                    allowEmptyCreatedAt: this.allowEmptyCreatedAt,
                };

                this.processImport({
                    importId: this.importId,
                    payload,
                })
                    .then(() => {
                        this.$notify.success(this.$t('imports.alerts.processedSuccessfully'));
                        this.$wait.end('saving.import');
                        this.$router.replace({ name: 'imports.index' });
                    })
                    .catch(error => {
                        this.$wait.end('saving.import');

                        if (error.response && error.response.status == 422) {
                            this.errors = error.response.data.errors;
                            return;
                        }

                        this.appendNewError({
                            code: '0110',
                            display: true,
                            error,
                            payload,
                        });
                    });
            },

            showField(field) {
                return !['related_id', 'sid'].includes(field) || this.leadType == LeadType.EVENT;
            },

            updateLeadType({ id }) {
                this.leadType = id;
            },

            updateEventSegment(segment) {
                this.eventSegment = segment;
            },

            updateImportRule(rule) {
                this.importRule = rule;
            },

            updateField(field, args) {
                this.$set(this.fields, field, args[0]);
            },

            getCustomFields() {
                const customFieldsObject = { customFields: {} };

                this.contextAccount.getLeadCustomFields().forEach(customField => {
                    customFieldsObject.customFields[`customId_${customField.id}`] = true;
                });

                return customFieldsObject;
            },

            onAllowEmptyCreatedDate() {
                if (this.allowEmptyCreatedAt) {
                    this.allowEmptyCreatedAt = false;
                } else {
                    this.allowEmptyCreatedDateModalOpened = true;
                }
            },
        },

        created() {
            this.fetch();
        },
    };
</script>
